import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import PropTypes from "prop-types";
import styles from "./Markdown.module.css";
import remarkSlug from 'remark-slug';
import remarkGfm from 'remark-gfm';

const MarkDown = ({ content }) => {
  return (
    <ReactMarkdown rehypePlugins={[rehypeRaw, remarkGfm]} remarkPlugins={[remarkSlug]} className={styles.reactMarkDown}>
      {content}
    </ReactMarkdown>
  );
};

export default MarkDown;

MarkDown.propTypes = {
  content: PropTypes.node.isRequired,
};
