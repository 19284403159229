/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { menuItems } from "./menuItems";
import { close, lightening, menu } from "src/assets/icons";
import { useWindowSize } from "src/hooks/useWindowSize";
import { useDispatch, useSelector } from "react-redux";
import { authSelector, setRedirectUrl } from "src/components/app/auth/slice";
import logo from "src/assets/images/axolo-logo.png";
import logoWihoutWord from "src/assets/images/axolo-logo-without-word.png";
import SignIn from "src/components/app/auth";
import styles from "./Header.module.css";
import {
  getListURLs,
  listUrlsSelector,
} from "src/components/app/select-new-url/slice";

const Header = () => {
  const menuRef = useRef();
  const [isSigninCalled, setSigninCalled] = useState(false);
  const { isUserLoggedIn } = useSelector(authSelector);
  const { redirectUrl } = useSelector(authSelector);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { urls } = useSelector(listUrlsSelector);
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { label: url, value: id, metric } = urls.length > 1 && urls[0];

  const handleOpenMenu = () => {
    setIsMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleClickMenu = () => {
      if (menuRef.current) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener("mousedown", handleClickMenu);
    return () => window.removeEventListener("mousedown", handleClickMenu);
  }, [isMenuOpen]);

  function generateUrl(urls) {
    let redirectUrl;
    switch (true) {
      case urls.length > 1 && isUserLoggedIn:
        redirectUrl = `app?id=${id}&url=${url}&metric=${metric}`;
        break;
      case urls.length === 1 && isUserLoggedIn:
        redirectUrl = "/app/first-user-login";
        break;
      default:
        redirectUrl = "#optimize";
        break;
    }
    return redirectUrl;
  }
  const fetchListUrls = async () => {
    try {
      await dispatch(getListURLs()).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (urls) {
      const url = generateUrl(urls);
      dispatch(setRedirectUrl(url));
    }
  }, [urls]);

  useEffect(() => {
    if (isSigninCalled) {
      navigate(redirectUrl);
    }
  }, [isSigninCalled, redirectUrl]);

  useEffect(() => {
    const firstUserLogin = localStorage.getItem("first_user_login");
    if (isUserLoggedIn) {
      fetchListUrls();

      if (!firstUserLogin && urls.length === 0) {
        localStorage.setItem("first_user_login", "true");
      } else {
        localStorage.removeItem("first_user_login");
      }
    }
  }, [isUserLoggedIn]);

  const setSessionStorage = (data) => {
    Object.entries(data).forEach(([key, value]) => {
      if (!sessionStorage.getItem(key)) {
        sessionStorage.setItem(key, value);
      }
    });
  };

  useEffect(() => {
    if (isUserLoggedIn && urls.length > 1) {
      const baseUrl = window.location.href.endsWith("/")
        ? window.location.href.slice(0, -1)
        : window.location.href;
      const currentUrl = `${baseUrl}/app?id=${id}&url=${url}&metric=${metric}`;

      const data = {
        optimized_url: !url.startsWith("http") ? `https://${url}` : url,
        optimization_id: id,
        metric: metric,
        current_url: currentUrl,
      };
      setSessionStorage({ ...data });
    }
  }, [urls]);

  return (
    <header className={styles.header}>
      <div className={styles.logo_wrapper}>
        <Link to="/">
          <img
            src={windowSize.width <= 430 ? logoWihoutWord : logo}
            alt="Axolo Logo"
          />
        </Link>
      </div>
      <div className={styles.nav_wrapper}>
        <nav style={{ paddingTop: "10px" }}>
          <ul className={styles.nav_list}>
            {menuItems.map((item) => (
              <li key={item.id}>
                <a href={item.link} target={item.target}>
                  {item.title}
                </a>
              </li>
            ))}
            {!isUserLoggedIn && (
              <li>
                <SignIn isInHomePage={true} setSigninCalled={setSigninCalled} />
              </li>
            )}
          </ul>
        </nav>
        <a href={redirectUrl} className={styles.optimize}>
          {!isUserLoggedIn ? "Start Optimizing" : "Go to Dashboard"}
          <div>{lightening()}</div>
        </a>
      </div>

      {isMenuOpen && <div className={styles.overlay}></div>}
      <div ref={menuRef} className={styles.mobile_menu_wrapper}>
        <button className={styles.menu_icon} onClick={handleOpenMenu}>
          {menu()}
        </button>
        <div
          className={`${styles.mobile_menu} ${isMenuOpen ? styles.active : ""}`}
        >
          <button className={styles.close_icon} onClick={handleCloseMenu}>
            {close()}
          </button>
          <ul className={styles.menu_list}>
            {menuItems.map((item) => (
              <li key={item.id}>
                <a href={item.link}>{item.title}</a>
              </li>
            ))}
            {!isUserLoggedIn && (
              <li>
                <SignIn isInHome={true} />
              </li>
            )}
            <a href={generateUrl(urls)} className={styles.optimize}>
              {!isUserLoggedIn ? "Start Optimizing" : "Go to Dashboard"}
              <div>{lightening()}</div>
            </a>
          </ul>

          <div className={styles.logo_wrapper}>
            <Link to="/">
              <img src={logo} alt="Axolo Logo" />
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
