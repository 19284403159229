import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isUserLoggedIn: localStorage.getItem("access_token") ? true : false,
  userData: [],
  redirectUrl: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setRedirectUrl: (state, action) => {
      state.redirectUrl = action.payload;
    },
    setUserLoggedIn: (state, action) => {
      localStorage.setItem("access_token", action.payload);
      state.isUserLoggedIn = true;
    },
    setUserLoggedOut: (state, action) => {
      window.location.href = "/";
      localStorage.clear();
      sessionStorage.clear();
      state.isUserLoggedIn = action.payload;
    },
  },
});

export const { setUserLoggedIn, setUserLoggedOut, setRedirectUrl } =
  authSlice.actions;

export const authSelector = (state) => state.auth;

export default authSlice.reducer;
