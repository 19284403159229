import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

const IdeasProtection = ({ children }) => {
  const location = useLocation();
  const optimizedURL = sessionStorage.getItem("optimized_url");
  const redirectParam = location.search.includes("redirect=true");

  if (redirectParam) return children;

  return optimizedURL === null ? <Navigate to="/" /> : children;
};

export default IdeasProtection;

IdeasProtection.propTypes = {
  children: PropTypes.node.isRequired,
};
