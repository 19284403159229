import { Component } from "react";
import { reload } from "src/assets/icons";
import PropTypes from "prop-types";
import Button from "../button";
import styles from "./ErrorBoundary.module.css";
import { Link } from "react-router-dom";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
    };
  }

  componentDidCatch(error) {
    this.setState({
      error,
    });
  }

  render() {
    if (this.state.error) {
      const handleReload = () => {
        location.reload();
      };
      return (
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <p className={styles.message}>{this.props.message}</p>
            <div className={styles.button_wrapper}>
              <Button className={styles.button} onClick={handleReload}>
                <div>Reload</div> <div>{reload()}</div>
              </Button>
            </div>
            <div>
              or return to {""}
              <Link className={styles.home_url} to="/">
                Home Page
              </Link>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  message: PropTypes.string.isRequired,
};

ErrorBoundary.defaultProps = {
  message: "Unexpected error occured.",
};
