import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GoogleLogin } from "@react-oauth/google";
import { authSelector, setUserLoggedIn } from "./slice";
import { showToast } from "src/helpers/showToast";
import PropTypes from "prop-types";
import Button from "src/components/button";
import CustomModal from "src/components/modal/CustomModal";
import * as axoloServices from "src/services/axolo";
import styles from "./SignIn.module.css";

const SignIn = ({ isInHomePage, setSigninCalled }) => {
  const [isSigninModalOpen, setSigninModalOpen] = useState(false);
  const { isUserLoggedIn } = useSelector(authSelector);
  const dispatch = useDispatch();

  const handleLogin = async (response) => {
    try {
      await getUserData(response);
      setSigninModalOpen(false);
      showToast("success", "You've successfully signed in to Axolo.");
      if (!isInHomePage) {
        window.location.reload();
      } else {
        setSigninCalled(true);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const getUserData = async (response) => {
    try {
      const res = await axoloServices.login(response.credential);
      const accesToken = res.data.data.token;
      const userData = res.data.data.user;
      localStorage.setItem("user", JSON.stringify(userData));
      dispatch(setUserLoggedIn(accesToken));
    } catch (error) {
      throw new Error(error.message);
    }
  };

  if (isUserLoggedIn) return null;

  return (
    <div>
      <Button
        className={`${!isInHomePage ? styles.app_signin : styles.home_signin}`}
        onClick={() => setSigninModalOpen(true)}
      >
        Sign In
      </Button>
      {isSigninModalOpen && (
        <CustomModal
          title="Continue with Your Account"
          height="max-content"
          isOpen={isSigninModalOpen}
          setIsOpen={setSigninModalOpen}
          isCloseBtnVisible={true}
        >
          <div className={styles.content_wrapper}>
            <p className={styles.content}>
              Save the generated ideas by signing in or creating a new account
              with your Google account.
            </p>
            <GoogleLogin
              size="large"
              text="continue_with"
              width={400}
              onSuccess={(credentialResponse) => {
                handleLogin(credentialResponse);
              }}
            />
          </div>
        </CustomModal>
      )}
    </div>
  );
};

SignIn.propTypes = {
  isInHomePage: PropTypes.bool,
  setSigninCalled: PropTypes.func,
};

SignIn.defaultValues = {
  isInHomePage: false,
};

export default SignIn;
