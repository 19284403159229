import PropTypes from "prop-types";
import styles from "./Spinner.module.css";

const Spinner = ({ width, height }) => {
  return (
    <div className={styles.wrapper}>
      <span
        style={{ width: width, height: height }}
        className={styles.spinner}
      ></span>
    </div>
  );
};

export default Spinner;

Spinner.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};
