export const menuItems = [
  {
    id: 1,
    title: "Product",
    link: "/#product",
  },
  {
    id: 2,
    title: "Pricing",
    link: "/#pricing",
  },
  {
    id: 3,
    title: "Autopilot",
    link: "/#autopilot",
  },
  {
    id: 4,
    title: "Updates",
    link: "https://pages.axolo.ai/updates",
    target: "_blank",
  },
];
