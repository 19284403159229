import { BottomSheet } from "react-spring-bottom-sheet";
import { appMenu } from "src/assets/icons";
import PropTypes from "prop-types";
import "react-spring-bottom-sheet/dist/style.css";

import styles from "./CustomBottomSheet.module.css";

const CustomBottomSheet = ({
  children,
  className,
  isOpen,
  setIsOpen,
  blocking,
}) => {
  return (
    <>
      <button className={styles.button} onClick={() => setIsOpen(true)}>
        {appMenu()}
      </button>
      <BottomSheet
        open={isOpen}
        scrollLocking
        onDismiss={() => setIsOpen(false)}
        blocking={blocking}
        className={className}
      >
        {children}
      </BottomSheet>
    </>
  );
};

export default CustomBottomSheet;

CustomBottomSheet.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  blocking: PropTypes.bool,
};
