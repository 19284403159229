import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as axoloServices from "src/services/axolo";
import { Base64 } from "js-base64";

export const getWebsite = createAsyncThunk(
  "website/getWebsite",
  async (url, { rejectWithValue }) => {
    const safeURL = Base64.encodeURI(url);
    try {
      const res = await axoloServices.renderURLWithProxy(safeURL);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  html: { __html: "" },
  isLoading: false,
  errorMessageOnGetWebsite: "",
};

export const websiteSlice = createSlice({
  name: "website",
  initialState,
  reducers: {
    resetPrevWebsite: (state) => {
      state.html.__html = "";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getWebsite.pending, (state) => {
      state.isLoading = true;
      state.errorMessageOnGetWebsite = "";
    });
    builder.addCase(getWebsite.fulfilled, (state, action) => {
      state.html.__html = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getWebsite.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessageOnGetWebsite = action.payload;
    });
  },
});

export const websiteSelector = (state) => state.website;

export const { resetPrevWebsite } = websiteSlice.actions;

export default websiteSlice.reducer;
