import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { store } from "./store/index.js";
import { tagManagerArgs } from "./utils/tagManagerArg.js";
import initSentry from "./utils/sentryConfig.js";
import "./styles/index.css";

TagManager.initialize(tagManagerArgs);

initSentry();

ReactDOM.createRoot(document.getElementById("root")).render(
  <GoogleOAuthProvider clientId={import.meta.env.VITE_APP_GOOGLE_AUTH_KEY}>
    <Provider store={store}>
      <App />
    </Provider>
  </GoogleOAuthProvider>
);
