import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as axoloServices from "src/services/axolo";

export const getIdeas = createAsyncThunk(
  "ideas/getIdeas",
  async ({ optimizationID, after }, { rejectWithValue }) => {
    try {
      const res = await axoloServices.getIdeas(optimizationID, after);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  ideasData: [],
  isReRun: false,
  reRunLoading: false,
  meta: "",
  isLoading: false,
  errorMessageOnGetIdeas: "",
};

export const ideasSlice = createSlice({
  name: "ideas",
  initialState,
  reducers: {
    resetPrevData: (state) => {
      state.ideasData = [];
    },
    setReRunIdeas: (state, action) => {
      state.isReRun = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIdeas.pending, (state) => {
      state.isLoading = true;
      state.errorMessageOnGetIdeas = "";
      if (state.isReRun) {
        state.reRunLoading = true;
      }
    });
    builder.addCase(getIdeas.fulfilled, (state, action) => {
      if (!state.isReRun) {
        state.ideasData = [...state.ideasData, ...action.payload.data];
      } else {
        state.ideasData = [...action.payload.data];
      }
      state.isReRun = false;
      state.reRunLoading = false;
      state.meta = action.payload.meta;

      if (!action.payload.meta.poll) {
        state.isLoading = false;
      }
    });
    builder.addCase(getIdeas.rejected, (state, action) => {
      state.isLoading = false;
      state.reRunLoading = false;
      state.errorMessageOnGetIdeas = action.payload;
    });
  },
});

export const ideasSelector = (state) => state.ideas;

export const { resetPrevData, setReRunIdeas } = ideasSlice.actions;

export default ideasSlice.reducer;
