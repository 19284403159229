import { Link } from "react-router-dom";
import logo from "src/assets/images/axolo-logo-blue.png";
import Logout from "src/components/app/logout";
import Tokens from "src/components/app/tokens";
import styles from "./FirstUserLogin.module.css";
import { useState } from "react";
import CustomSelect from "src/components/select";
import { customStyles } from "./constants";
import CustomModal from "src/components/modal/CustomModal";
import AddNewPage from "src/components/app/select-new-url/add-new-page";

const options = [
  {
    label: "+ Add New Page",
    value: "add_new_page",
  },
];

const FirstUserLoginPage = () => {
  const [isNewUrlModalOpen, setNewUrlModalOpen] = useState(true);

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <main>
      <header className={styles.header}>
        <div className={styles.left_side}>
          <div className={styles.logo_wrapper}>
            <Link
              to={window.location.href}
              onClick={refreshPage}
              style={{ cursor: "pointer" }}
            >
              <img src={logo} alt="Axolo Logo" />
            </Link>
          </div>
          <div className={styles.select_wrapper}>
            <CustomSelect
              options={options}
              defaultValue={options}
              customStyles={customStyles}
              disabled
            />
          </div>
          {isNewUrlModalOpen && (
            <CustomModal
              isOpen={isNewUrlModalOpen}
              setIsOpen={setNewUrlModalOpen}
              isCloseBtnVisible={false}
              title="Generate New Ideas"
              overlayColor="transparent"
              zIndex={-1}
            >
              <AddNewPage content="You haven’t added a URL to optimize for yet. To start generating AB test ideas that’ll optimize your website, add your landing page URL and select a conversion metric." />
            </CustomModal>
          )}
        </div>
        <div className={styles.right_side}>
          <Tokens />
          <Logout />
        </div>
      </header>
    </main>
  );
};

export default FirstUserLoginPage;
