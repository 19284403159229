import { useEffect, useState } from "react";
import Header from "src/components/landing/header";
import content from "src/texts/terms-of-use.md";
import styles from "./TermsOfUse.module.css";
import MarkDown from "src/components/markdown/Markdown";
import Footer from "src/components/landing/footer";
import ScrollToTop from "src/components/scrrol-to-top";
import { updateMetaTags } from "src/helpers/metaTagCreator";

const TermsOfUse = () => {
  const [termsOfUseText, setTermsOfUseText] = useState("");

  useEffect(() => {
    updateMetaTags(
      "Terms of User | Axolo",
      "Read the terms and conditions of Axolo.",
      window.location.href
    );
    fetch(content)
      .then((res) => res.text())
      .then((text) => setTermsOfUseText(text));
  }, []);
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Header />
        <MarkDown content={termsOfUseText} />
        <Footer />
        <ScrollToTop />
      </div>
    </div>
  );
};

export default TermsOfUse;
