import PropTypes from "prop-types";
import Select from "react-select";
import styles from "./CustomSelect.module.css";
import "./index.css";

const CustomSelect = ({
  onChange,
  placeholder,
  options,
  isRequired,
  customStyles,
  defaultValue,
  disabled,
}) => {
  return (
    <Select
      id="url-selector"
      classNamePrefix="custom-select"
      onChange={onChange}
      isDisabled={disabled}
      defaultValue={defaultValue || "Select"}
      styles={customStyles}
      placeholder={placeholder}
      options={options}
      className={`${styles.custom_select} ${isRequired && styles.required}`}
    />
  );
};

CustomSelect.propTypes = {
  customStyles: PropTypes.object.isRequired,
  options: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  isRequired: PropTypes.object,
  defaultValue: PropTypes.array,
  disabled: PropTypes.bool,
};

export default CustomSelect;
