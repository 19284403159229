import { token } from "src/assets/icons";
import PropTypes from "prop-types";
import styles from "./BuyTokens.module.css";
import { convertCentsToDollars } from "src/helpers/concertCentsToDollars";
import Button from "src/components/button";
import * as axoloServices from "src/services/axolo";
import { useEffect, useState } from "react";
import Spinner from "src/components/spinner";

const BuyTokens = ({ setVisibility }) => {
  const [tokenOptions, setTokenOPtions] = useState([]);
  const [redirectStatus, setRedirectStatus] = useState(false);
  const userData = JSON.parse(localStorage.getItem("user"));
  const { tokens_count } = userData;

  const getOptions = async () => {
    try {
      const res = await axoloServices.getBuyingOptions();
      const filteredOptions = res.data.data
        .reverse()
        .filter((option) => option.id);
      setTokenOPtions(filteredOptions);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOptions();
  }, []);

  const handleGoToStripe = async (priceID) => {
    setRedirectStatus(true);
    try {
      const res = await axoloServices.goStripeForPayment(priceID);
      const { url } = res.data.data;
      window.open(url, "blank");
      setVisibility(false);
    } catch (error) {
      console.log(error);
    } finally {
      setRedirectStatus(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <p>
          You have <b>{tokens_count || 0}</b> remaining{" "}
          {tokens_count && tokens_count > 1 ? "tokens" : "token"}.
        </p>
      </div>
      <div className={styles.options}>
        {!redirectStatus && <p className={styles.sub_title}>Buy new tokens</p>}
        <div className={styles.inner}>
          {redirectStatus ? (
            <div className={styles.redirect_text}>
              You are being redirected to the payment page.{" "}
              <span style={{ marginTop: "5px", display: "inline-block" }}>
                Please wait
              </span>
              <span className={styles.animated_ellipsis}></span>
            </div>
          ) : tokenOptions && tokenOptions.length > 0 ? (
            tokenOptions.map((option) => (
              <Button
                id={`buy-tokens-${option.id}`}
                key={option.id}
                target="_blank"
                onClick={() => {
                  handleGoToStripe(option.id);
                  localStorage.setItem(
                    "purchased_tokens_count",
                    parseInt(option.name)
                  );
                }}
                className={styles.option_btn}
              >
                <div className={styles.icon}>{token()}</div>
                <div className={styles.info}>{`${parseInt(
                  option.name
                )} / $${convertCentsToDollars(option.price)}`}</div>
              </Button>
            ))
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </div>
  );
};

export default BuyTokens;

BuyTokens.propTypes = {
  setVisibility: PropTypes.func,
};
