import { useEffect, useState } from "react";
import { arrowRightPurple, rightArrow } from "src/assets/icons";
import { clickedElementPosition } from "src/helpers/getPositionClickedElement";
import PropTypes from "prop-types";
import styles from "./ScrollToTop.module.css";

const ScrollToTop = ({ isInIdeas }) => {
  const [isIconVisible, setIsIconVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 200) {
        setIsIconVisible(true);
      } else {
        setIsIconVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const handleScroll = () => {
    window.scrollTo({
      top: clickedElementPosition || 0,
      behavior: "smooth",
    });
  };

  if (!isIconVisible) return null;

  return (
    <div
      className={styles.scroller_wrapper}
      style={{ bottom: isInIdeas }}
      role="button"
      onClick={handleScroll}
    >
      {isIconVisible && (
        <div className={styles.button}>
          {!isInIdeas ? (
            <div className={styles.white_arrow}>{rightArrow()}</div>
          ) : (
            <div className={styles.purple_arrow}>{arrowRightPurple()}</div>
          )}
        </div>
      )}
    </div>
  );
};

ScrollToTop.propTypes = {
  isInIdeas: PropTypes.bool,
  isSvgBlack: PropTypes.bool,
};

export default ScrollToTop;
