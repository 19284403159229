import CustomModal from "src/components/modal/CustomModal";
import Header from "../header";
import { useEffect, useState } from "react";
import AddNewPage from "src/components/app/select-new-url/add-new-page";
import styles from "./Success.module.css";
import { successful } from "src/assets/icons";
import Button from "src/components/button";
import * as axoloServices from "src/services/axolo";
import { Navigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelector } from "src/components/app/auth/slice";

function Success() {
  const [searchParams] = useSearchParams();
  const userData = JSON.parse(localStorage.getItem("user"));
  const [isNewUrlModalOpen, setNewUrlModalOpen] = useState(false);
  const [purchasedTokenCount, setPurchasedTokenCount] = useState(0);
  const tokenCount = localStorage.getItem("purchased_tokens_count");
  const { isUserLoggedIn } = useSelector(authSelector);

  useEffect(() => {
    if (searchParams) {
      assignPurchasedTokens();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const assignPurchasedTokens = async () => {
    const param = Object.fromEntries([...searchParams]);
    const { session_id: CHECKOUT_ID } = param;
    try {
      const res = await axoloServices.depositToken(CHECKOUT_ID);
      const { tokens_count } = res.data.data;
      const newUserData = {
        ...userData,
        tokens_count: userData.tokens_count + tokens_count,
      };
      localStorage.setItem("user", JSON.stringify(newUserData));
      setPurchasedTokenCount(tokens_count);
    } catch (error) {
      console.log(error);
    }
  };

  if (!isUserLoggedIn) return <Navigate to="/" />;

  return (
    <>
      <Header />
      {!isNewUrlModalOpen && (
        <div className={styles.content_wrapper}>
          <div className={styles.inner}>
            <div>{successful()}</div>
            <h2>Your purchase is successful!</h2>
            <p>
              You’ve successfully purchased{" "}
              <b>{purchasedTokenCount || tokenCount || ""}</b>{" "}
              {tokenCount > 1 ? "tokens" : "token"} and they’re added to your
              account. To continue generating ideas that’ll drive the conversion
              rates on your landing pages, select an existing URL, or add a new
              one from the URL selector.
            </p>
            <Button
              className={styles.button}
              onClick={() => setNewUrlModalOpen(true)}
            >
              Open URL Selector
            </Button>
          </div>
        </div>
      )}
      {isNewUrlModalOpen && (
        <CustomModal
          isOpen={isNewUrlModalOpen}
          setIsOpen={setNewUrlModalOpen}
          isCloseBtnVisible={true}
          title="Add New Page"
        >
          <AddNewPage />
        </CustomModal>
      )}
    </>
  );
}

export default Success;
