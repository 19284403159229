export const customStyles = {
  option: (provided, { isFocused }) => ({
    ...provided,
    width: "100%",
    borderBottom: "1px solid #B9B9B9",
    padding: 18,
    fontFamily: "Rubik",
    color: "#696969",
    overflow: "hidden",
    backgroundColor: isFocused ? "#f6f6f6" : "#fff",
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: "40px",
    padding: "1px 1px 1px 18px",
    width: "100%",
    fontFamily: "Rubik",
    color: "#7D7D7D",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#7d7d7d",
    fontSize: "20px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#7D7D7D",
  }),
  input: (provided) => ({
    ...provided,
    color: "#7D7D7D",
  }),
};
