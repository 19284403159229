import logo from "src/assets/images/axolo-logo.png";
import styles from "./Footer.module.css";
import { heart } from "src/assets/icons";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.logo_wrapper}>
        <img src={logo} alt="Axolo Logo" />
      </div>
      <div className={styles.heart_icon}>{heart()}</div>
      <div className={styles.link_wrapper}>
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/terms-of-use">Terms Of Use</Link>
      </div>
    </footer>
  );
};

export default Footer;
