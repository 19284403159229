import { MutatingDots } from "react-loader-spinner";
import styles from "./Loading.module.css";

const Loading = () => {
  return (
    <div className={styles.wrapper}>
      <MutatingDots height="100" width="100" color="#FFFFFF" secondaryColor="#FFFFFF" ariaLabel="mutating-dots-loading" radius="12.5" visible={true} />
    </div>
  );
};

export default Loading;
