import {
  customStyles,
  inputPlaceholder,
  newUrlPlaceholder,
  options,
  selectPlaceholder,
} from "./constants";
import { warning } from "src/assets/icons";
import { func, string } from "prop-types";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { removeTraillingSlash } from "src/helpers/removeTrailingSlash";
import { getOptimizedData } from "src/components/landing/optimize/slice";
import { showToast } from "src/helpers/showToast";
import * as axoloServices from "src/services/axolo";
import MetricSelectionForm from "../../metric-selection/Form";
import ErrorMessage from "src/components/error-message";
import styles from "./AddNewPage.module.css";

const classNames = {
  form: styles.form,
  input: styles.input,
  required: styles.required,
  new_url_input: styles.new_url_input,
  label: styles.label,
  button: styles.button,
  new_url_metric: styles.new_url_metric,
};

const MESSAGES = {
  tokenWarning:
    "You don’t have enough tokens to generate new ideas. Buy new tokens to add a new page.",
  addNewPage:
    "Once you add a new page, Axolo will automatically use 1 token to generate 10 ideas to optimize that page.",
  experimentalProduct:
    "Axolo is an experimental product, and we’re not able to support your website for the time being. Yet, we’ll get better and better everyday. Subscribe to product news.",
};

const AddNewPage = ({ content }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("user"));
  const [isLoading, setIsLoading] = useState(false);
  const [isTokenWarnVisible, setTokenWarnVisible] = useState(false);
  const [isErrorMessageVisible, setErrorMessageVisible] = useState(false);
  const tokensCount = userData?.tokens_count || 0;
  const controller = new AbortController();

  useEffect(() => {
    if (tokensCount === 0) {
      setTokenWarnVisible(true);
    }
  }, [tokensCount]);

  const handleSubmit = async (data) => {
    if (tokensCount === 0) return;

    setIsLoading(true);
    setErrorMessageVisible(false);

    const submittedUrl = formatUrl(data.url);
    sessionStorage.setItem("optimized_url", removeTraillingSlash(submittedUrl));

    try {
      await handleTokenValidation(submittedUrl);
      const optimizedData = await dispatch(
        getOptimizedData({ url: submittedUrl, controller })
      ).unwrap();
      await handleMetricSelection(
        optimizedData.data,
        data.metricText || data.metric,
        submittedUrl
      );

      updateUserData(userData.tokens_count - 1);
    } catch (error) {
      handleErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatUrl = (url) => (!url.startsWith("http") ? `https://${url}` : url);

  const handleTokenValidation = async (url) => {
    try {
      await axoloServices.checkTokenValidation({ url });
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleMetricSelection = async (meta, metric, url) => {
    try {
      const { data } = await axoloServices.metricSelection({
        meta,
        metric,
        url,
      });
      const { id, metric: selectedMetric } = data.data;
      sessionStorage.setItem("optimization_id", id);
      sessionStorage.setItem("metric", selectedMetric);
      navigateToApp(id, url, selectedMetric);
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const navigateToApp = (id, url, selectedMetric) => {
    navigate(`/app?id=${id}&url=${url}&metric=${selectedMetric}`);
    window.location.reload();
  };

  const updateUserData = (newTokenCount) => {
    const updatedUserData = { ...userData, tokens_count: newTokenCount };
    localStorage.setItem("user", JSON.stringify(updatedUserData));
  };

  const handleErrors = (error) => {
    console.error(error);
    if (error.message.includes("Token count")) {
      setErrorMessageVisible(true);
    } else {
      showToast("error", error.message);
    }
  };

  const RenderErrorMessage = () => (
    <div className={styles.error_message_wrapper}>
      <p className={styles.error_message_content}>
        <strong>Axolo</strong> is an <strong>experimental product</strong>, and
        we’re not able to support your website for the time being. Yet, we’ll
        get better and better every day. Please{" "}
        <a href="/#autopilot">subscribe to product news</a>.
      </p>
    </div>
  );

  const TokenWarn = () =>
    isTokenWarnVisible && (
      <div className={styles.warn_container}>
        <div>{warning()}</div>
        <p>{MESSAGES.tokenWarning}</p>
      </div>
    );

  return (
    <div className={styles.wrapper}>
      <p className={styles.content}>{content}</p>
      {isErrorMessageVisible && (
        <ErrorMessage>
          <RenderErrorMessage />
        </ErrorMessage>
      )}
      <MetricSelectionForm
        onSubmit={handleSubmit}
        isLoading={isLoading}
        newUrlInputName="url"
        selectInputName="metric"
        metricInputName="metricText"
        customStyles={customStyles}
        inputPlaceholder={newUrlPlaceholder}
        newUrlPlaceholder={inputPlaceholder}
        selectPlaceholder={selectPlaceholder}
        options={options}
        classNames={classNames}
        newUrlInput={true}
        btnContent={<div>Add New Page</div>}
      />
      <TokenWarn />
    </div>
  );
};

export default AddNewPage;

AddNewPage.propTypes = {
  content: string,
  setOpen: func,
};

AddNewPage.defaultProps = {
  content: MESSAGES.addNewPage,
};
