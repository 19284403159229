/* eslint-disable react-hooks/exhaustive-deps */
import { customStyles } from "./constants";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../auth/slice";
import { useEffect, useLayoutEffect, useState } from "react";
import { getIdeas, ideasSelector, setReRunIdeas } from "../display-ideas/slice";
import { getWebsite } from "src/components/website/slice";
import { useRef } from "react";
import CustomModal from "src/components/modal/CustomModal";
import CustomSelect from "src/components/select";
import SelectModal from "./add-new-page";
import styles from "./SelectNewUrl.module.css";
import { getListURLs, listUrlsSelector, updateListUrls } from "./slice";
import { resetPrevData } from "src/components/landing/optimize/slice";
import { useNavigate } from "react-router-dom";

const SelectNewUrl = () => {
  const { isUserLoggedIn } = useSelector(authSelector);
  const { isLoading, reRunLoading } = useSelector(ideasSelector);
  const [isSelectModalOpen, setSelectModalOpen] = useState(false);
  const firstRender = useRef(true);
  const navigate = useNavigate();
  const selectedUrlRef = useRef("");
  const dispatch = useDispatch();
  const { urls } = useSelector(listUrlsSelector);
  const optimizedURL = sessionStorage.getItem("optimized_url");
  const optimizationID = sessionStorage.getItem("optimization_id");

  const defaultOptions = [
    {
      label: optimizedURL || "+ Add New Page",
      value: optimizedURL || "add_new_page",
    },
  ];

  const handleChange = async (e) => {
    const { value, label, metric } = e;
    if (value === "add_new_page") {
      setSelectModalOpen(true);
      return;
    }

    navigate(`/app?id=${value}&url=${label}&metric=${metric}`);

    if (value !== optimizationID || selectedUrlRef.current !== optimizationID) {
      selectedUrlRef.current = value;
      sessionStorage.setItem("optimized_url", label);
      sessionStorage.setItem("optimization_id", value);
      dispatch(resetPrevData());
      dispatch(setReRunIdeas(true));
      await dispatch(getWebsite(label)).unwrap();
      await dispatch(getIdeas(value)).unwrap();
    } else {
      selectedUrlRef.current = value;
    }
  };

  const handleFetchListUrls = async () => {
    try {
      await dispatch(getListURLs()).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isUserLoggedIn) {
      handleFetchListUrls();
    }
  }, [isUserLoggedIn]);

  useLayoutEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    const handleUpdateListUrls = async () => {
      try {
        await dispatch(updateListUrls(optimizationID)).unwrap();
      } catch (error) {
        console.log(error);
      }
    };

    if (urls && isUserLoggedIn) {
      const urlLabels = urls.map((url) => url.label);
      if (!urlLabels.includes(optimizedURL)) {
        handleUpdateListUrls();
      }
    }
  }, [isUserLoggedIn]);

  const adjustOptions = () => {
    if (isUserLoggedIn && urls.length > 1) {
      return urls;
    } else if (isUserLoggedIn && urls.length === 1) {
      const defaults = [
        {
          label: "Add New Page",
          value: "add_new_page",
        },
      ];
      return defaults;
    }
  };

  if (!optimizedURL) return;

  return (
    <div className={styles.select_wrapper}>
      <CustomSelect
        onChange={handleChange}
        options={adjustOptions()}
        defaultValue={defaultOptions}
        disabled={!isUserLoggedIn || isLoading}
        customStyles={customStyles}
      />
      {isSelectModalOpen && (
        <CustomModal
          isOpen={isSelectModalOpen}
          setIsOpen={setSelectModalOpen}
          isCloseBtnVisible={!reRunLoading}
          title="Add New Page"
        >
          <SelectModal />
        </CustomModal>
      )}
    </div>
  );
};

export default SelectNewUrl;
