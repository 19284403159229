import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { removeTraillingSlash } from "src/helpers/removeTrailingSlash";
import * as axoloServices from "src/services/axolo";

export const getListURLs = createAsyncThunk(
  "listUrls/getListURLs",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axoloServices.fetchListUrls();
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateListUrls = createAsyncThunk(
  "listUrls/updateListUrls",
  async (optimizationID, { rejectWithValue }) => {
    try {
      const res = await axoloServices.updateListUrls(optimizationID);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  urls: [],
  isLoading: false,
  isReturningUser: false,
  updateStatus: false,
  errorMessageOnUpdateList: "",
  errorMessageOnGetListURLs: "",
};

export const listUrlsSlice = createSlice({
  name: "listUrls",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getListURLs.pending, (state) => {
      state.isLoading = true;
      state.errorMessageOnGetListURLs = "";
    });
    builder.addCase(getListURLs.fulfilled, (state, action) => {
      const mappedUrls = action.payload.data.map((item) => ({
        value: item.id,
        label: removeTraillingSlash(item.url),
        metric: item.metric,
      }));
      state.urls = [
        ...mappedUrls,
        {
          value: "add_new_page",
          label: "+ Add New Page",
        },
      ];

      if (state.urls.length === 1) {
        localStorage.setItem("first_user_login", "true");
      } else {
        sessionStorage.setItem("is_details_modal_open", "true");
        localStorage.removeItem("first_user_login");
      }
      localStorage.setItem(
        "optimized_details",
        JSON.stringify(action.payload.data)
      );
      state.isLoading = false;
    });
    builder.addCase(getListURLs.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessageOnGetListURLs = action.payload;
    });
    builder.addCase(updateListUrls.pending, (state) => {
      state.updateStatus = true;
    });
    builder.addCase(updateListUrls.fulfilled, (state, action) => {
      const { id, url, metric } = action.payload.data;

      const newUrl = [
        {
          value: id,
          label: url,
          metric: metric,
        },
      ];
      state.urls = [...newUrl, ...state.urls];
      state.updateStatus = false;
      state.errorMessageOnGetListURLs = "";
    });
    builder.addCase(updateListUrls.rejected, (state, action) => {
      state.updateStatus = false;
      state.errorMessageOnUpdateList = action.payload;
    });
  },
});

export const listUrlsSelector = (state) => state.listUrls;

export default listUrlsSlice.reducer;
