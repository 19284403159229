import { Link } from "react-router-dom";
import { useWindowSize } from "src/hooks/useWindowSize";
import logo from "src/assets/images/axolo-logo.png";
import logoWithoutWord from "src/assets/images/axolo-logo-without-word.png";
import { useEffect } from "react";
import { updateMetaTags } from "src/helpers/metaTagCreator";
import styles from "./NotFound.module.css";

const NotFound = () => {
  const windowSize = useWindowSize();

  useEffect(() => {
    updateMetaTags(
      "Page Not Found | Axolo",
      "We can`t find the page you are looking for right now. Maybe it never existed.",
      window.location.href
    );
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h1>404</h1>
        <p>We can`t find the page you are looking for right now.</p>
        <p className={styles.link}>
          Why don`t you try returning to the <Link to="/"> home page</Link> ?
        </p>
      </div>
      <div className={styles.logo}>
        <img
          src={windowSize.width <= 400 ? logoWithoutWord : logo}
          alt="Axolo Logo"
        />
      </div>
    </div>
  );
};

export default NotFound;
