import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as axoloServices from "src/services/axolo";

export const getOptimizedData = createAsyncThunk(
  "optimize/getOptimizedData",
  async ({ url, controller }, { rejectWithValue }) => {
    try {
      const res = await axoloServices.getOptimizedData({ url, controller });
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateOptimizedData = createAsyncThunk(
  "optimize/updateOptimizedData",
  async ({ optimizationID, submittedData }, { rejectWithValue }) => {
    try {
      const res = await axoloServices.editDetails({
        optimizationID,
        submittedData,
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  initialData: [],
  updatedData: [],
  optimizedURL: undefined,
  isLoading: false,
  requestErrorMessage: "",
  errorMessageOnUpdate: "",
};

export const optimizeSlice = createSlice({
  name: "optimize",
  initialState,
  reducers: {
    resetPrevData: (state) => {
      state.initialData = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOptimizedData.pending, (state) => {
      state.isLoading = true;
      state.requestErrorMessage = "";
    });
    builder.addCase(getOptimizedData.fulfilled, (state, action) => {
      state.initialData = action.payload;
      state.optimizedURL = action.meta.arg.url;
      state.isLoading = false;
    });
    builder.addCase(getOptimizedData.rejected, (state, action) => {
      state.isLoading = false;
      state.requestErrorMessage = action.payload;
    });
    builder.addCase(updateOptimizedData.pending, (state) => {
      state.errorMessageOnUpdate = "";
    });
    builder.addCase(updateOptimizedData.fulfilled, (state, action) => {
      state.updatedData = action.payload.data.meta;
    });

    builder.addCase(updateOptimizedData.rejected, (state, action) => {
      state.errorMessageOnUpdate = action.payload;
    });
  },
});

export const optimizeSelector = (state) => state.optimize;

export const { resetPrevData } = optimizeSlice.actions;

export default optimizeSlice.reducer;
