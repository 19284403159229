import toast from "react-hot-toast";

export const showToast = (type, message, options = {}) => {
  const {
    duration = 4000,
    position = "top-center",
    style = { fontFamily: "Rubik", color: "#fff" },
    ariaProps = { role: "status", "aria-live": "polite" },
    icon = null,
  } = options;

  toast[type](message, {
    duration,
    position,
    style,
    icon,
    ariaProps,
  });
};
