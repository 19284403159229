import PropTypes from "prop-types";

const ErrorMessage = ({ children }) => {
  return <>{children}</>;
};

ErrorMessage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorMessage;
