import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { authSelector } from "src/components/app/auth/slice";

function FirstUserProtection({ children }) {
  const { isUserLoggedIn } = useSelector(authSelector);
  const firstUserLogin = localStorage.getItem("first_user_login");

  return isUserLoggedIn && firstUserLogin ? children : <Navigate to="/" />;
}

export default FirstUserProtection;

FirstUserProtection.propTypes = {
  children: PropTypes.node.isRequired,
};
