export const customStyles = {
  option: (provided, { isFocused }) => ({
    ...provided,
    borderBottom: "1px solid #B9B9B9",
    padding: 18,
    fontFamily: "Rubik",
    color: "#696969",
    backgroundColor: isFocused ? "#f6f6f6" : "#fff",
  }),
  control: (provided, { isFocused }) => ({
    ...provided,
    borderRadius: "40px",
    padding: "9px 1px 9px 18px",
    height: "54px",
    width: "100%",
    fontFamily: "Rubik",
    color: "#7D7D7D",
    boxShadow: isFocused && "none",
    borderColor: isFocused && "none",
    "&:hover": {
      borderColor: isFocused && "none",
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#696969",
    fontSize: "40px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#7D7D7D",
  }),
};

export const options = [
  { value: "cta_click_rate", label: "CTA Click Rate" },
  { value: "form_submission_rate", label: "Form Submission Rate" },
  { value: "signup_button_click_rate", label: "Signup Button Click Rate" },
  { value: "bounce_rate", label: "Bounce Rate" },
  { value: "other", label: "Other" },
];

export const selectPlaceholder = "Select a metric to optimize...";
export const inputPlaceholder = "Add your landing page URL here...";
export const newUrlPlaceholder =
  "Enter a metric to optimize your landing page...";
