import { useRef, useState } from "react";
import { googleLogout } from "@react-oauth/google";
import { logout } from "src/assets/icons";
import { useDispatch } from "react-redux";
import { setUserLoggedOut } from "../auth/slice";
import Button from "src/components/button";
import styles from "./Logout.module.css";
import Profile from "../profile";
import useClickOutside from "src/hooks/useHandleClickOutside";

const Logout = () => {
  const userData = JSON.parse(localStorage.getItem("user"));
  const [isLogoutBtnVisible, setLogoutBtnVisible] = useState(false);
  const dispatch = useDispatch();

  const logoutRef = useRef(null);

  useClickOutside(logoutRef, () => setLogoutBtnVisible(false));

  const handleLogOut = () => {
    dispatch(setUserLoggedOut(false));
    googleLogout();
  };

  if (userData && userData.length === 0) return null;

  return (
    <div
      role="button"
      ref={logoutRef}
      onClick={() => setLogoutBtnVisible(!isLogoutBtnVisible)}
      className={styles.wrapper}
    >
      <Profile />
      {isLogoutBtnVisible && (
        <div className={styles.logout}>
          <Button className={styles.logout_btn} onClick={handleLogOut}>
            <div>Logout</div> <div>{logout()}</div>
          </Button>
        </div>
      )}
    </div>
  );
};

export default Logout;
