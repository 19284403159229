import { useEffect, useState } from "react";
import styles from "./Profile.module.css";
import { useSelector } from "react-redux";
import { authSelector } from "../auth/slice";

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const { isUserLoggedIn } = useSelector(authSelector);
  const data = localStorage.getItem("user");

  useEffect(() => {
    if (isUserLoggedIn) {
      if (data) {
        setUserData(JSON.parse(data));
      }
    }
  }, [isUserLoggedIn, data]);

  if (!userData || !isUserLoggedIn) return null;

  return (
    <div className={styles.profile_img}>
      <div className={styles.overlay}></div>
      {userData && (
        <img
          src={userData.avatar_url}
          referrerPolicy="no-referrer"
          alt="User Profile"
        />
      )}
    </div>
  );
};

export default Profile;
