import { bool, func, node, oneOfType, string } from "prop-types";
import styles from "./Button.module.css";

const Button = ({
  children,
  className,
  type = "button",
  disabled,
  onClick,
  id,
  value,
}) => {
  return (
    <button
      type={type}
      value={value}
      onClick={onClick}
      id={id}
      disabled={disabled}
      className={`${className} ${styles.custom_button}`}
    >
      {children}
    </button>
  );
};

export default Button;

Button.propTypes = {
  children: node.isRequired,
  className: string.isRequired,
  type: string,
  disabled: oneOfType([bool, string]),
  onClick: func,
  id: string,
  value: string,
};
