import { token } from "src/assets/icons";
import styles from "./Token.module.css";
import { useSelector } from "react-redux";
import { authSelector } from "../auth/slice";
import BuyTokens from "./buy/BuyTokens";
import { useEffect, useRef, useState } from "react";
import Button from "src/components/button";
import useClickOutside from "src/hooks/useHandleClickOutside";
import { useLocation } from "react-router-dom";
import { useWindowSize } from "src/hooks/useWindowSize";

const Tokens = () => {
  const { isUserLoggedIn } = useSelector(authSelector);
  const userData = JSON.parse(localStorage.getItem("user"));
  const [isTokenOptionsVisible, setTokenOptionsVisible] = useState(false);
  const tokenOptionsRef = useRef(null);
  const location = useLocation();
  const windowSize = useWindowSize();

  useEffect(() => {
    if (
      location.pathname === "/app/payment-failed" &&
      windowSize.width >= 767
    ) {
      setTokenOptionsVisible(true);
    }
  }, [location, windowSize]);

  useClickOutside(tokenOptionsRef, () => setTokenOptionsVisible(false));

  if (!isUserLoggedIn) return null;

  return (
    <div className={styles.tokens_wrapper} ref={tokenOptionsRef}>
      <Button
        id="tokens-button"
        className={styles.tokens_button}
        onClick={() => setTokenOptionsVisible(!isTokenOptionsVisible)}
      >
        <div className={styles.tokens}>
          {token()} {userData?.tokens_count || 0}
        </div>
      </Button>
      {isTokenOptionsVisible && (
        <BuyTokens setVisibility={setTokenOptionsVisible} />
      )}
    </div>
  );
};

export default Tokens;
