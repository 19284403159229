import { RouterProvider } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import router from "./router";

function App() {
  return (
    <>
      <RouterProvider router={router} />
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: "#3ace59",
            },
          },
          error: {
            style: {
              background: "#FE0000",
            },
          },
        }}
      />
    </>
  );
}

export default App;
