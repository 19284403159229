export const updateMetaTags = (title, description, url = {}) => {
    document.title = title;
    // Set or update description
    let meta = document.querySelector('meta[name="description"]');
    if (meta) meta.content = description;
    else {
        meta = document.createElement('meta');
        meta.setAttribute("name", "description");
        meta.setAttribute("content", description);
        document.head.appendChild(meta);
    }
    // Set or update og:url
    meta = document.querySelector('meta[property="og:url"]');
    if (meta) meta.content = url;
    else {
        meta = document.createElement('meta');
        meta.setAttribute("property", "og:url");
        meta.setAttribute("content", url);
        document.head.appendChild(meta);
    }
    // Set or update og:title
    meta = document.querySelector('meta[property="og:title"]');
    if (meta) meta.content = title;
    else {
        meta = document.createElement('meta');
        meta.setAttribute("property", "og:title");
        meta.setAttribute("content", title);
        document.head.appendChild(meta);
    }
    // Set or update og:description
    meta = document.querySelector('meta[property="og:description"]');
    if (meta) meta.content = description;
    else {
        meta = document.createElement('meta');
        meta.setAttribute("property", "og:description");
        meta.setAttribute("content", description);
        document.head.appendChild(meta);
    }
}