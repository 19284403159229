import { paymentFail } from "src/assets/icons";
import Header from "../header";
import styles from "./Fail.module.css";
import { useSelector } from "react-redux";
import { authSelector } from "src/components/app/auth/slice";
import { Navigate } from "react-router-dom";

function Fail() {
  const { isUserLoggedIn } = useSelector(authSelector);
  const tokenCount = localStorage.getItem("purchased_tokens_count");

  if (!isUserLoggedIn) return <Navigate to="/" />;

  return (
    <>
      <Header />
      <div className={styles.content_wrapper}>
        <div className={styles.inner}>
          <div>{paymentFail()}</div>
          <h2>Your purchase attempt has failed.</h2>
          <p>
            We encountered an issue while processing your purchase of{" "}
            <b>{tokenCount || ""}</b> {tokenCount > 1 ? "tokens" : "tokens"} for
            your Axolo account.
          </p>
        </div>
      </div>
    </>
  );
}

export default Fail;
