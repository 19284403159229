import Modal from "react-modal";
import { string, bool, func, node, number } from "prop-types";
import styles from "./CustomModal.module.css";
import { x } from "src/assets/icons";
import { useWindowSize } from "src/hooks/useWindowSize";

Modal.setAppElement("#root");

const CustomModal = ({
  isOpen,
  setIsOpen,
  title,
  children,
  height = "max-content",
  isCloseBtnVisible,
  overlayColor = "rgba(255, 255, 255, 0.75)",
  zIndex = 1000,
}) => {
  const windowSize = useWindowSize();

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      className={styles.reactModal}
      style={{
        overlay: {
          zIndex: zIndex,
          position: "fixed",
          height: "100%",
          width: "100%",
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: overlayColor,
        },
        content: {
          top: "50%",
          left: "50%",
          bottom: windowSize.width >= 767 ? "auto" : "-40%",
          right: "auto",
          height: height,

          width: windowSize.width >= 560 ? "478px" : "90%",
          padding: windowSize.width >= 560 ? "30px 25px" : "10px",
          border: "1px solid #E7E7E7",
          borderRadius: "10px",
          transform: "translate(-50%, -50%)",
          fontFamily: "Rubik",
          overflowY:
            windowSize.width <= 767 || windowSize.height <= 890
              ? "scroll"
              : "hidden",
        },
      }}
    >
      <div className={styles.header}>
        {title && <h3 className={styles.title}>{title}</h3>}
        {isCloseBtnVisible && (
          <button className={styles.button} onClick={closeModal}>
            {x()}
          </button>
        )}
      </div>
      {children}
    </Modal>
  );
};

export default CustomModal;

CustomModal.propTypes = {
  title: string,
  isOpen: bool.isRequired,
  setIsOpen: func.isRequired,
  isCloseBtnVisible: bool,
  children: node.isRequired,
  height: string,
  overlayColor: string,
  zIndex: number,
};
