import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useWindowSize } from "src/hooks/useWindowSize";
import { useForm, Controller } from "react-hook-form";
import { useState } from "react";
import Button from "src/components/button";
import CustomSelect from "src/components/select";
import PropTypes from "prop-types";
import Spinner from "src/components/spinner";

const MetricSelectionForm = ({
  onSubmit,
  options,
  isLoading,
  customStyles,
  selectPlaceholder,
  inputPlaceholder,
  newUrlPlaceholder,
  classNames,
  btnContent,
  newUrlInput,
  newUrlInputName,
  selectInputName,
  metricInputName,
}) => {
  const windowSize = useWindowSize();
  const [parent] = useAutoAnimate();
  const [isInputFieldVisible, setIsInputFieldVisible] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleSelectChange = (selectedOption) => {
    if (selectedOption.value === "other") {
      setIsInputFieldVisible(true);
      return;
    }

    setIsInputFieldVisible(false);
  };

  return (
    <form
      ref={!newUrlInput ? parent : null}
      className={classNames.form}
      onSubmit={handleSubmit(onSubmit)}
    >
      {newUrlInput && (
        <Controller
          control={control}
          name={newUrlInputName}
          rules={{ required: true }}
          render={({ field }) => (
            <>
              <label className={classNames.label} htmlFor={newUrlInputName}>
                Landing Page
              </label>
              <input
                name={newUrlInputName}
                type="text"
                value={field.value || ""}
                className={`${classNames.new_url_input} ${
                  errors[newUrlInputName] && classNames.required
                }`}
                placeholder={newUrlPlaceholder}
                onChange={field.onChange}
              />
            </>
          )}
        />
      )}
      <Controller
        control={control}
        name={selectInputName}
        rules={{ required: true }}
        render={({ field }) => (
          <>
            {newUrlInput && (
              <label className={classNames.label}>Conversion Metric</label>
            )}
            <CustomSelect
              options={options}
              disabled={isLoading}
              isRequired={errors[selectInputName]}
              customStyles={customStyles}
              placeholder={
                windowSize.width <= 471
                  ? `${selectPlaceholder.substring(0, 16)}...`
                  : selectPlaceholder
              }
              onChange={(value) => {
                field.onChange(value.value || "");
                handleSelectChange(value);
              }}
            />
          </>
        )}
      />
      {isInputFieldVisible && (
        <Controller
          control={control}
          name={metricInputName}
          rules={{ required: true }}
          render={({ field }) => (
            <div
              className={`${classNames.input_wrapper} ${
                newUrlInput && classNames.new_url_metric
              }`}
            >
              <input
                name={metricInputName}
                type="text"
                id="metric-selection-input-field"
                className={`${classNames.input}  ${
                  errors[metricInputName] && classNames.required
                }`}
                placeholder={
                  windowSize.width <= 360
                    ? `${inputPlaceholder.substring(0, 27)}...`
                    : inputPlaceholder
                }
                value={field.value || ""}
                disabled={isLoading}
                onChange={field.onChange}
              />
            </div>
          )}
        />
      )}
      <Button
        id="metric-selection-generate-button"
        type="submit"
        className={classNames.button}
        disabled={isLoading}
      >
        {isLoading ? <Spinner /> : btnContent}
      </Button>
    </form>
  );
};

export default MetricSelectionForm;

MetricSelectionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  newUrlInputName: PropTypes.string,
  selectInputName: PropTypes.string.isRequired,
  metricInputName: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  customStyles: PropTypes.object.isRequired,
  selectPlaceholder: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string.isRequired,
  classNames: PropTypes.object.isRequired,
  newUrlPlaceholder: PropTypes.string,
  newUrlInput: PropTypes.bool,
  btnContent: PropTypes.node.isRequired,
};
