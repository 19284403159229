import { configureStore } from "@reduxjs/toolkit";
import optimizeReducer from "src/components/landing/optimize/slice";
import ideasReducer from "src/components/app/display-ideas/slice";
import authReducer from "src/components/app/auth/slice";
import websiteReducer from "src/components/website/slice";
import listUrlsSlice from "src/components/app/select-new-url/slice";

export const store = configureStore({
  reducer: {
    optimize: optimizeReducer,
    ideas: ideasReducer,
    auth: authReducer,
    website: websiteReducer,
    listUrls: listUrlsSlice,
  },
});
