import axiosInstance from "src/utils/axiosInstance";

const unexpectedError = "Unexpected error occured. Please try later.";
let getIdeasController = new AbortController();

export const abortGetIdeas = () => {
  getIdeasController.abort();
  getIdeasController = new AbortController();
};

export const checkTokenValidation = async (url) => {
  try {
    return await axiosInstance.post("api/site_specs", url);
  } catch (error) {
    throw new Error(error.response.data.error.message || unexpectedError);
  }
};

export const renderURLWithProxy = async (base_64_encoded_url) => {
  return await axiosInstance.get(`/proxy/${base_64_encoded_url}`);
};

export const getOptimizedData = async ({ url, controller }) => {
  const config = controller ? { signal: controller.signal } : null;
  try {
    return await axiosInstance.post("api/site_meta", { url }, config);
  } catch (error) {
    throw new Error("Unexpected error occured.");
  }
};

export const metricSelection = async (data) => {
  try {
    return await axiosInstance.post("api/optimizations", data);
  } catch (error) {
    throw new Error(error.response.data.error.message || unexpectedError);
  }
};

export const getIdeas = async (optimizationID, after = null) => {
  return await axiosInstance.get(
    `api/optimizations/${optimizationID}/experiments/`,
    {
      params: { after },
      signal: getIdeasController.signal,
    }
  );
};

export const generateNewIdeas = async (optimizationID) => {
  return await axiosInstance.post(
    `/api/optimizations/${optimizationID}/execution`
  );
};

export const login = async (google_token) => {
  try {
    return await axiosInstance.post("/api/users", { token: google_token });
  } catch (error) {
    throw new Error(error.response.data.error.message || unexpectedError);
  }
};

export const fetchListUrls = async () => {
  return axiosInstance.get("/api/optimizations");
};

export const updateListUrls = async (optimizationID) => {
  return await axiosInstance.patch(`/api/optimizations/${optimizationID}`);
};

export const editDetails = async ({ optimizationID, submittedData }) => {
  return await axiosInstance.patch(`api/optimizations/${optimizationID}`, {
    ...submittedData,
  });
};

export const getBuyingOptions = async () => {
  return await axiosInstance.get("/api/payments/prices");
};

export const goStripeForPayment = async (priceID) => {
  const submittedData = {
    price_id: priceID,
  };
  return axiosInstance.post("/api/payments/checkouts", { ...submittedData });
};

export const depositToken = async (CHECKOUT_ID) => {
  return axiosInstance.patch(`/api/payments/checkouts/${CHECKOUT_ID}`);
};

export const sendFeedbackRate = async (optimizationId, rating) => {
  try {
    return await axiosInstance.post(
      `/api/optimizations/${optimizationId}/feedback`,
      { rating }
    );
  } catch (error) {
    throw new Error(error.response.data.error.message || unexpectedError);
  }
};

export const sendFeedbackForm = async ({
  formData,
  feedbackId,
  optimizationId,
}) => {
  try {
    return await axiosInstance.patch(
      `/api/optimizations/${optimizationId}/feedback/${feedbackId}`,
      formData
    );
  } catch (error) {
    throw new Error(error.response.data.error.message || unexpectedError);
  }
};
