/* eslint-disable react-refresh/only-export-components */
import { Suspense, lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import IdeasProtection from "src/@protection/IdeasProtection";
import SetupProtection from "src/@protection/SetupProtection";
import Loading from "src/components/loading";
import FirstUserLoginPage from "src/pages/first-user-login";
import NotFound from "src/pages/not-found";
import TermsOfUse from "src/pages/terms-of-use";
import FirstUserProtection from "src/@protection/FirstUserProtection";
import ErrorBoundary from "src/components/error-boundary/ErrorBoundary";
import Success from "src/pages/payments/success";
import Fail from "src/pages/payments/fail";

const Home = lazy(() => import("src/pages/home"));
const Setup = lazy(() => import("src/pages/setup"));
const AxoloApp = lazy(() => import("src/pages/app"));
const PrivacyPolicy = lazy(() => import("src/pages/privacy-policy"));

const router = createBrowserRouter([
  {
    path: "/",
    index: true,
    Component: () => (
      <Suspense fallback={<Loading />}>
        <Home />
      </Suspense>
    ),
  },
  {
    path: "/app/setup",
    Component: () => (
      <Suspense fallback={<Loading />}>
        <SetupProtection>
          <Setup />
        </SetupProtection>
      </Suspense>
    ),
  },
  {
    path: "/app",
    Component: () => (
      <Suspense fallback={<Loading />}>
        <IdeasProtection>
          <AxoloApp />
        </IdeasProtection>
      </Suspense>
    ),
  },
  {
    path: "/app/first-user-login",
    Component: () => (
      <Suspense fallback={<Loading />}>
        <FirstUserProtection>
          <ErrorBoundary message="Unexpected error occured. Please try logging in again or continuing without logging in.">
            <FirstUserLoginPage />
          </ErrorBoundary>
        </FirstUserProtection>
      </Suspense>
    ),
  },
  {
    path: "/app/payment-succeeded",
    Component: () => (
      <Suspense fallback={<Loading />}>
        <ErrorBoundary message="Unexpected error occured.">
          <Success />
        </ErrorBoundary>
      </Suspense>
    ),
  },
  {
    path: "/app/payment-failed",
    Component: () => (
      <Suspense fallback={<Loading />}>
        <ErrorBoundary message="Unexpected error occured.">
          <Fail />
        </ErrorBoundary>
      </Suspense>
    ),
  },
  {
    path: "/privacy-policy",
    Component: () => (
      <Suspense fallback={<Loading />}>
        <PrivacyPolicy />
      </Suspense>
    ),
  },
  {
    path: "/terms-of-use",
    Component: () => (
      <Suspense fallback={<Loading />}>
        <TermsOfUse />
      </Suspense>
    ),
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default router;
