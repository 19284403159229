import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

const SetupProtection = ({ children }) => {
  const optimizedURL = sessionStorage.getItem("optimized_url");

  return optimizedURL === null ? <Navigate to="/" /> : children;
};

export default SetupProtection;

SetupProtection.propTypes = {
  children: PropTypes.node.isRequired,
};
