import logo from "src/assets/images/axolo-logo-blue.png";
import Tokens from "src/components/app/tokens";
import Logout from "src/components/app/logout";
import { Link } from "react-router-dom";
import styles from "./Header.module.css";
import SelectNewUrl from "src/components/app/select-new-url";
import { useState } from "react";
import { useWindowSize } from "src/hooks/useWindowSize";
import CustomBottomSheet from "src/components/bottom-sheet";

function Header() {
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const windowSize = useWindowSize();

  return (
    <header className={styles.header}>
      {windowSize.width >= 767 ? (
        <>
          <div className={styles.left_side}>
            <div className={styles.logo_wrapper}>
              <Link to="/" style={{ cursor: "pointer" }}>
                <img src={logo} alt="Axolo Logo" />
              </Link>
            </div>
            <div className={styles.select_wrapper}>
              <SelectNewUrl />
            </div>
          </div>
          <div className={styles.right_side}>
            <Tokens />
            <Logout />
          </div>
        </>
      ) : (
        <>
          <div className={styles.logo_wrapper}>
            <Link to="/" style={{ cursor: "pointer" }}>
              <img src={logo} alt="Axolo Logo" />
            </Link>
          </div>
          <Tokens />
          <CustomBottomSheet
            isOpen={isBottomSheetOpen}
            setIsOpen={setIsBottomSheetOpen}
            blocking={false}
          >
            <div className={styles.mobile_wrapper}>
              <SelectNewUrl />
              <Logout />
            </div>
          </CustomBottomSheet>
        </>
      )}
    </header>
  );
}

export default Header;
