export let clickedElementPosition = "";

export const getPositionClickedElement = (event) => {
  if (event) {
    const position = event.currentTarget.getBoundingClientRect();
    clickedElementPosition = position.top + window.scrollY - 500;
  }

  return clickedElementPosition;
};
